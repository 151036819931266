.clean-block.clean-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url("../../assets/img/oildigger.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0;
}

.clean-block .block-heading {
  padding-top: 120px;
  margin-bottom: 40px;
  text-align: center;
}

.clean-block.clean-hero {
  margin-top: -70px;
  /* Adjust for navbar overlap */
}

.clean-block.clean-hero .col-md-4 {
  flex: 0 0 calc(33.333% - 20px);
  /* Ensure equal sizing */
  max-width: calc(33.333% - 20px);
  margin: 10px;
}

.clean-block.clean-hero .img-fluid {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.clean-block.clean-hero .btn {
  margin-top: 40px;
}

.clean-block.clean-hero .counters .container {
  display: flex;
  padding-top: 100px;
  justify-content: center;
  gap: 30px;
  text-align: center;
}

@media (max-width: 700px) {
  .clean-block.clean-hero .counters .container {
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
  }

  .clean-block.clean-hero .counters .container>div {
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }

  .clean-block.clean-hero .counters .container>div:last-child {
    border-bottom: none;
  }
}

.counters .box {
  background: #ffffff;
  color: black;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.counters .box .counter {
  font-size: 45px;
  margin: 5px 0;
}

.counters .box h3 {
  color: #0f479a;
}

@media (max-width: 700px) {
  .counters .box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.text {
  text-align: center;
  padding: 50px 0;
}

.text h2 {
  margin-bottom: 20px;
}

.text .row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.text .col-md-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 10px;
  text-align: center;
}

.text .col-md-4 img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.button-container {
  margin-top: 20px;
}

.container.rental-page {
  padding: 150px 0 300px;
  text-align: center;
  margin-top: 50px;
}

.rental-title {
  font-size: 2rem;
  margin-bottom: 100px;
  color: #333;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}



.clean-block.partner h2 {
  font-size: 2.5rem;
  margin-bottom: 60px;
  color: #333;
}

.clean-block.partner p {
  margin-bottom: 50px;
  color: #666;
}

.clean-block.partner {
  padding: 50px 0;
  text-align: center;
  position: relative;
  /* Ensure the container is positioned */
  overflow: hidden;
  /* Hide the overflow */
}

.clean-block.partner .logos-wrapper {
  display: flex;
  align-items: center;
  /* Center logos vertically */
  overflow: hidden;
  /* Hide overflowing logos */
  position: relative;
  /* Create a positioning context */
  width: 100%;
  /* Full width to ensure scrolling */
}

.clean-block.partner .logos {
  display: flex;
  flex-direction: row;
  /* Arrange logos horizontally */
  width: 200%;
  /* Double the width to accommodate the duplicate set */
  animation: scrollHorizontal 20s linear infinite;
  /* Animate the horizontal scroll */
}

.clean-block.partner .logos .logo-set {
  display: flex;
  flex-direction: row;
  /* Arrange logos horizontally within each set */
}

.clean-block.partner .logos .logo-set img {
  max-width: 150px;
  /* Adjust size as needed */
  margin: 0 10px;
  /* Space between logos */
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.clean-block.partner .logos .logo-set img:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Keyframes for horizontal scrolling animation */
@keyframes scrollHorizontal {
  0% {
    transform: translateX(0);
    /* Start position */
  }

  100% {
    transform: translateX(-50%);
    /* End position, half of the container width */
  }
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .clean-block.partner .logos .logo-set img {
    max-width: 100px;
    /* Adjust logo size for smaller screens */
  }
}