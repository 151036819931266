.vanilla-zoom {
  width: 100%;
  /*display: flex;*/
}

.vanilla-zoom .sidebar {
  /*flex-basis: 30%;*/
  width: 100%;
  display: flex;
  /*flex-direction: column;*/
}

.vanilla-zoom .sidebar img.small-preview {
  width: 60px;
  margin-right: 5px;
  cursor: pointer;
  opacity: .5;
}

.vanilla-zoom .sidebar img.small-preview.active, .vanilla-zoom .sidebar img.small-preview:hover {
  opacity: 1;
}

.vanilla-zoom .sidebar img.small-preview:last-child {
  margin-right: 0;
}

.vanilla-zoom .zoomed-image {
  width: 100%;
  height: 300px;
  flex: 1;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  margin-bottom: 5px;
}

