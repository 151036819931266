/* CSS for the Products section */
.products {
  padding: 50px 0;
  background-color: #f8f9fa;
  color: #333;
  text-align: center;
}
.text h2 {
  display: flex;
  padding-bottom: 50px;
}
.products .row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Center items horizontally */
}

.products .col-md-4 {
  flex: 1 1 30%; /* Flex grow, flex shrink, flex-basis */
  max-width: 300px; /* Set a maximum width for product boxes */
  margin: 0px; /* Space between products */
  display: flex;
  justify-content: center;
  align-items: center;
}
.products .product-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure full height */
  width: 100%; /* Ensure full width */
  max-width: 300px; /* Set a maximum width for product boxes */
  opacity: 0; /* Initially hide elements */
  transform: translateY(20px); /* Move elements down to prepare for animation */
  transition: opacity 0.6s ease, transform 0.6s ease; /* Smooth transition for opacity and transform */
}
/* Media query for mobile devices */
@media (max-width: 767px) {
  .products .row {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }
}
@media (max-width: 407px) {
  .products{
    padding-top: 70px;
  }
}
  
.products .product-content {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 100%; /* Ensure full height */
  width: 100%; /* Ensure full width */
  max-width: 300px; /* Set a maxximum width for product boxes */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center; /* Center align text */
  position: relative; /* Ensure relative positioning for absolute child */
}

.products .product-content:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

.products .product-content img {
  max-width: 100%; /* Ensure image width is constrained */
  height: auto; /* Maintain aspect ratio */
  height: 100%; /* Ensure full height */
  width: 100%; /* Ensure full width */
  max-width: 300px; /* Set a maximum width for product boxes */
  padding-bottom: 40px;
  border-radius: 8px;
}

.product-name {
  position: absolute; /* Position absolute to overlap with button */
  bottom: 70px; /* Adjust as needed */
  left: 0;
  right: 0;
  text-align: center; /* Center align text */
  z-index: 1; /* Ensure product name appears above the button */
}

.btn-quote {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center; /* Align button to center horizontally */
  position: relative; /* Ensure button remains within product-content */
  z-index: 2; /* Ensure button appears above product name */
}

.btn-quote:hover {
  background-color: #0056b3;
}
