.page-footer {
    background-image: linear-gradient(158deg, #1d0180, #037db1, #249fd3, #023650);
    margin-top: 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 20px; /* Adjust the padding to create space from the element above */
    padding-bottom: 10px; /* Adjust as needed */
  }
  
  .page-footer.dark h5 {
    margin-top: 20px;
    color: #b5fb66;
    margin-top: 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 20px; /* Adjust the padding to create space from the element above */
    padding-bottom: 10px; /* Adjust as needed */
  }
  
  .page-footer hr {
    width: 90%;
    margin-left: 5vw;
    border: 1px solid white;
  }
  
  .footer-copyright {
    border: 0px;
    /* background-color: lightblue; */
    background-image: linear-gradient(158deg, #015580, #0492ce, #0394d1, #015580);
    /* background-color: #d1ebf5; */
  }
  