.features-blue {
  color: #fff;
  background: linear-gradient(135deg, #172a74, #21a9af);
  background-color: #184e8e;
  padding-bottom: 30px;
}

@media (max-width:767px) {
  .features-blue {
    padding-bottom: 10px;
  }
}

.features-blue p {
  color: rgba(255,255,255,0.6);
}

.features-blue h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width:767px) {
  .features-blue h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.features-blue .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto 60px;
}

@media (max-width:767px) {
  .features-blue .intro {
    margin-bottom: 40px;
  }
}

.features-blue .item {
  min-height: 100px;
  padding-left: 80px;
  margin-bottom: 40px;
}

@media (max-width:767px) {
  .features-blue .item {
    min-height: 0;
  }
}

.features-blue .item .name {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 20px;
  color: inherit;
}

.features-blue .item .description {
  font-size: 15px;
  margin-bottom: 0;
}

.features-blue .item .icon {
  font-size: 40px;
  color: #ffcc1e;
  float: left;
  margin-left: -65px;
}

.counter-container {
  display: flex;
  gap: 20px;
}

.counter {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.counter h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.counter .number {
  display: block;
  font-size: 48px;
  font-weight: bold;
  color: #007bff;
}