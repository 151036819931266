/* Navbar styles */
.navbar {
  background-color: white;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ddd;
  z-index: 1000;
}

.navbar-brand {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.navbar-brand img {
  height: 50px;
  margin-right: 20px;
}




.navbar-nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: #333;
  font-size: 1rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-link:hover {
  color: #0056b3;
}

.nav-link.active {
  color: #0056b3;
  font-weight: bold;
}

/* Dropdown menu */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #333;
  text-align: left;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #333;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.dropdown-item:hover {
  color: #0056b3;
  background-color: #f8f9fa;
}

.nav-item.dropdown.show .dropdown-menu {
  display: block;
  opacity: 1;
}

/* Media query for mobile devices */
/* Navbar styles */
.navbar {
  background-color: white;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ddd;
  z-index: 1000;
}

.navbar-brand {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.navbar-brand img {
  height: 50px;
  margin-right: 20px;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  color: #333;
}

.navbar-nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: #333;
  font-size: 1rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-link:hover {
  color: #0056b3;
}

.nav-link.active {
  color: #0056b3;
  font-weight: bold;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .navbar-collapse {
    justify-content: flex-end;
  }
  .clean-navbar .logo {
    font-size: 1rem;
    }
  .dropdown-menu {
    display: none;
    position: relative;
    top: 0;
    left: 0;
    float: none;
    min-width: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    opacity: 1;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    color: #333;
    background-color: transparent;
    border-bottom: 1px solid #ddd; /* Add a line underneath each item */
  }

  .dropdown-item:hover {
    background-color: #f8f9fa;
  }
}
