.contact-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 100px;
    background-color: #f0f4f8;
}

.contact-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.contact-text {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #555;
    text-align: center;
    max-width: 600px;
}

.contact-info {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 600px;
    gap: 40px;
}

.contact-item {
    text-align: center;
}

.contact-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #1d72b8;
}

.contact-item p {
    font-size: 1.2rem;
    color: #333;
}

@media (max-width: 600px) {
    .contact-info {
        flex-direction: column;
        gap: 20px;
    }
}