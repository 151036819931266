.container.rental-page {
    padding-top: 150px;
    padding-bottom: 300px;
    text-align: center;
    margin-top: 50px;
  }
  
  .rental-title {
    font-size: 2rem;
    margin-bottom: 100px;
    color: #333;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px; /* Add space between rows */
  }
  
  .rental-btn {
    padding: 50px 50px; /* Adjust padding for a box shape */
    font-size: 1.2rem;
    border-radius: 5px;
    width: 200px; /* Adjust width for box shape */
    height: 150px; /* Adjust height for box shape */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .rental-btn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  